<template>
  <div>
    <!-- Menu::Start -->
    <div class="container mt-4">
      <div class="mt-auto" style="position: relative; top: 17px">
        <Form :form="form" route="citizen-report" purpose="add" />
      </div>
    </div>
    <!-- Menu::End -->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Form from "@/view/components/citizen-report/FormAnjungan.vue";
import moment from "moment";

export default {
  components: {
    Form,
  },
  data() {
    return {
      config: {},
      interval: null,
      time: null,
      form: {
        title: "",
        category_id: "",
        date_report: moment().format("YYYY-MM-DD"),
        citizen_id: "",
        citizen_name: "",
        description: "",
        status: "1",
      },
      error: {
        id_card_number: "",
        family_number: "",
        hamlet_id: "",
        start_date: "",
        name: "",
        purpose: "",
        address: "",
      },
      modal2: false,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    async getHamletHeadOption() {
      let response = await module.setTreeSelectHamletHead("hamlets");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.hamlets = response.data;
        this.hamlets.unshift({
          label: "Pilih No. WA Ketua Rw",
          id: "",
          isDisabled: true,
        });
      }
    },
    reset() {
      this.form.id_card_number = "";
      this.form.family_number = "";
      this.form.hamlet_id = "";
      //
      this.form.name = "";
      this.form.purpose = "";
      this.form.address = "";
      this.form.start_date = "";

      this.error.id_card_number = "";
      this.error.family_number = "";
      this.error.hamlet_id = "";
      //
      this.error.name = "";
      this.error.purpose = "";
      this.error.address = "";
      this.error.start_date = "";
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        "bussiness-certificate-no-auth"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit('close-pengaduan')
        this.reset();
      }
    },
  },
  mounted() {
    this.getSetting();
    this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}
</style>
