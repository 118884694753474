<template>
  <div class="curved-header sample1">
    <div class="row text-center justify-content-between px-10">
        <div class="col pt-8">
          <img
            style="height: 222px"
            :src="config.city_logo"
            alt="LOGO DESA"
          />
        </div>
        <div class="col-8 pt-8 px-19">
          <h1 style="font-size: 45px;
    color: rgb(29, 172, 116);
    font-weight: 600;">ANJUNGAN MANDIRI</h1>
          <h1
            style="font-size: 70px;
    font-weight: 600;
    color: rgb(29, 172, 116);
    position: relative;
    top: -20px;"
            class="text-uppercase"
          >
            DESA {{ config.village_name }}
          </h1>
          <span style="font-size: 20px;
    text-align: justify;
    color: rgb(59, 76, 66);
    position: relative;
    top: -40px;">{{
            config.address
          }}</span>
        </div>
        <div class="col pt-8">
          <img
            style="height: 211px"
            :src="config.village_chief_photo"
            alt="KEPALA DESA"
          />
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
  },
};
</script>

<style>
.curved-header {
  height: 221px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}
.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
  background: white;
}
</style>