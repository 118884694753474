<template>
  <div
    style="
      background-image: url(/media/anjungan/BG-1280x1024.jpg);
      height: 100%;
      background-size: cover;
    "
  >
    <!-- Header::Start -->
    <Header2 :config="config" />
    <!-- Header::End -->

    <!-- Menu::Start -->
    <div class="container-fluid px-40 mt-4">
      <!-- <div class="d-flex justify-content-end" style="margin-top: 100px">
        <div>
          <h2
            @click="$router.push('/anjungan-mandiri')"
            style="cursor: pointer"
          >
            <b-badge
              pill
              class="px-10 btn-cancel py-6"
              style="background: #dc10eb; color: white; font-size: 24px"
              >Kembali</b-badge
            >
          </h2>
        </div>
      </div> -->
      <!-- Breadcrumb::Start -->
      <div class="d-flex justify-content-between mt-10">
        <div>
          <h1
            style="font-size: 35px; color: white; font-weight: 600"
            @click="$router.push('/anjungan-mandiri/main')"
          >
            Layanan Mandiri
          </h1>
        </div>
        <div>
          <h2
            @click="$router.push('/anjungan-mandiri')"
            style="cursor: pointer"
          >
            <b-badge
              pill
              class="px-10 btn-cancel py-6"
              style="background: #dc10eb; color: white; font-size: 24px"
              >Kembali</b-badge
            >
          </h2>
        </div>
      </div>
      <!-- Breadcrumb::End -->
      <div class="row mt-auto" style="position: relative; top: 17px">
        <!-- <div class="col-4" @click="$router.push('/anjungan-mandiri/pengaduan-masyarakat')"> -->
        <div class="col-4 d-flex justify-content-center">
          <img
            @click="$bvModal.show('modal-pengaduan')"
            src="/media/anjungan/layer2/icon-2_Pamduan-Masyarakat.png"
            alt=""
          />
        </div>
        <div class="col-4 d-flex justify-content-center">
          <img
            src="/media/anjungan/layer2/icon-2_Surat-Menyurat.png"
            alt=""
            @click="$router.push('/anjungan-mandiri/surat-menyurat')"
          />
        </div>
      </div>
      <!-- <div class="d-flex justify-content-end mr-25" style="margin-top: 155px;">
          <div>
            <b-button
              type="button"
              class="ml-2 btn-cancel btn-lg"
              @click="$router.push('/anjungan-mandiri')"
            >
              Kembali
            </b-button>
          </div>
        </div> -->
    </div>
    <!-- Menu::End -->
    <!-- Footer::Start -->
    <Footer :config="config" />
    <!-- Footer::End -->

    <!-- modal -->
    <b-modal
      id="modal-pengaduan"
      size="xl"
      hide-footer
      content-class="my-custom-modal"
    >
      <template #modal-header="{ close }">
        <HeaderModalFormLetter :title="'Pengaduan Masyarakat'" :close="close" />
      </template>
      <template #modal-title
        ><span style="font-size: 20px" class="font-weight-bold"
          >Pengaduan Masyarakat</span
        ></template
      >
      <div style="height: 450px">
        <Pengaduan></Pengaduan>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Pengaduan from "@/view/pages/anjungan-mandiri/PengaduanMasyarakat.vue";
import module from "@/core/modules/CrudModule.js";
import Header2 from "@/view/components/anjungan-mandiri/Header2.vue";
import Footer from "@/view/components/anjungan-mandiri/Footer.vue";
import HeaderModalFormLetter from "@/view/components/anjungan-mandiri/HeaderModalFormLetter.vue";

import {
  setConfiguration,
  getConfiguration,
} from "@/core/services/jwt.service.js";
export default {
  components: {
    Header2,
    Footer,
    Pengaduan,
    HeaderModalFormLetter,
  },
  data() {
    return {
      config: getConfiguration() == null ? {} : getConfiguration(),
      interval: null,
      time: null,
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
  },
  mounted() {
    this.getSetting();
    this.$root.$on("close-pengaduan", function () {
      this.$bvModal.hide("modal-pengaduan");
    });
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped></style>
