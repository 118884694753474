<template>
  <div class="curved-footer sample2">
    <!-- <div class="d-flex justify-content-end">
      <div>
        <b-button
          type="button"
          class="ml-2 btn-cancel btn-lg"
          @click="$router.push('/anjungan-mandiri/layanan-mandiri')"
        >
          Kembali
        </b-button>
      </div>
    </div> -->
    <div class="d-flex justify-content-center">
      <h1 style="font-size: 100px; font-weight: 600; color: #1dac74">
        {{ time }}
      </h1>
    </div>
    <div class="d-flex justify-content-center">
      <marquee behavior="" direction="">
        <span style="color: #3b4c42; font-size: 30px" class="text-uppercase">{{
          config.running_text
        }}</span>
      </marquee>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
  },
  data() {
    return {
      interval: null,
      time: null,
    };
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    this.time = Intl.DateTimeFormat("en-GB", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format();

    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style>
.curved-footer {
  height: 195px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: fixed;
  bottom: -4px;
  width: 100%;
}
</style>