<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div data-wizard-type="step-content" data-wizard-state="current">
        <div class="row">
          <div class="col-md-4 py-0">
            <b-form-group id="input-group-title">
              <label for="input-nik"><h2 class="label-custom">NIK:</h2></label>
              <b-form-input
                class="input-custom"
                id="input-nik"
                v-model="getNik"
                placeholder="NIK"
              ></b-form-input>
              <h5 class="validate-custom" v-if="form.citizen_name != ''">
                Nama Penduduk : {{ form.citizen_name }}
              </h5>
              <h6 class="text-danger">
                {{ error.citizen_id }}
              </h6>
            </b-form-group>
          </div>
          <div class="col-md-4 py-0">
            <b-form-group id="input-group-category">
              <label for="input-category"
                ><h2 class="label-custom">Jenis Pengaduan:</h2></label
              >
              <b-form-select
                class="input-custom"
                id="input-category"
                v-model="form.category_id"
                :options="report_category"
              ></b-form-select>
              <h6 class="text-danger mt-1 validate-custom">
                {{ error.category_id }}
              </h6>
            </b-form-group>
          </div>
          <div class="col-md-4 py-0">
            <b-form-group id="input-group-title">
              <label for="input-name"
                ><h2 class="label-custom">Judul Pengaduan:</h2></label
              >
              <b-form-input
                class="input-custom"
                id="input-name"
                v-model="form.title"
                placeholder="Judul"
              ></b-form-input>
              <h6 class="text-danger mt-1 validate-custom">
                {{ error.title }}
              </h6>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="input-group-description">
          <label for="input-description"
            ><h2 class="label-custom">Deskripsi Pengaduan:</h2></label
          >
          <b-form-textarea
            class="input-custom"
            id="input-description"
            v-model="form.description"
            placeholder="Deskripsi"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <h6 class="text-danger mt-1 validate-custom">
            {{ error.description }}
          </h6>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between pt-3">
        <div>
          <b-button
            type="submit"
            variant="primary"
            v-if="citizen_registred_status == 0"
            class="btn-lg mr-2"
          >
            <span class="label-custom">Kirim</span>
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            disabled
            v-if="citizen_registred_status == 1"
            class="btn-lg mr-2"
          >
            <span class="label-custom">Kirim</span>
          </b-button>
          <b-button
            variant="secondary"
            type="button"
            class="ml-2 btn-lg mr-2"
            @click="$root.$emit('close-pengaduan')"
          >
            <span class="label-custom">Batal</span>
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        title: "",
        category_id: "",
        description: "",
        citizen_id: "",
      },

      report_category: {},
      timeout: null,
      citizen_registred_status: 0,
    };
  },
  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/anjungan-mandiri/layanan-mandiri");
      }
    },
    filterByCategory(evt) {
      if (typeof evt == "undefined") {
        this.form.category_id = "";
      }
      // this.pagination();
    },
    async getCategoryOption() {
      let response = await module.setSelectOption("category-citizen-report");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.report_category = response.data;
        this.report_category.unshift({
          text: "Pilih Jenis",
          value: "",
          disabled: true,
        });
      }
    },

    async pagination() {
      this.form.citizen_name = "";
      this.error.citizen_id = "";

      let response = await module.get(
        `citizen-by-nik/${this.form.citizen_number}`
      );

      if (response) {
        this.citizen_registred_status = 0;
        let res = response;
        this.form.citizen_id = res.id;
        this.form.citizen_name = res.name;
      } else {
        this.citizen_registred_status = 1;
        this.error.citizen_id =
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.";

        Swal.fire(
          "",
          "NIK anda belum terdaftar di sistem informasi desa. Silahkan datang ke kantor desa untuk mendaftarkan.",
          "error"
        );
      }
    },
  },
  computed: {
    getNik: {
      get() {
        return this.form.citizen_number;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.form.citizen_number = val;
          // this.form.citizen_id = val;
          if (val.length >= 16) {
            this.pagination();
          }
        }, 500);
      },
    },
  },
  mounted() {
    this.getCategoryOption();
  },
};
</script>

<style scoped>
.btn-cancel {
  background-color: #bbc15d;
  border-color: #bbc15d;
  color: black;
}
.text-validation {
  color: #bbc15d;
}
.input-custom {
  font-size: 25px;
  border-color: rgb(29, 172, 116);
}
.input-custom:focus {
  /* border-color: #bbc15d; */
  /* border-color: rgb(29, 172, 116); */
  border-color: #2989fe;
}
.label-custom {
  font-size: 22px;
  font-weight: bold;
}
.validate-custom {
  font-size: 20px;
  font-weight: bold;
}
.form-control-lg {
  height: 61px;
}
.form-control {
  border-color: rgb(29, 172, 116);
}

.form-control:focus {
  /* border-color: #bbc15d; */
  border-color: #2989fe;
}
</style>
