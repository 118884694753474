<template>
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <b style="font-size: 25px">{{ title }}</b>
      <div>
        <template v-if="scrollButton">
          <b-button type="button" class="btn-circle mr-3" @click="scrollUp"
            ><i class="fas fa-arrow-up px-0"></i
          ></b-button>
          <b-button type="button" class="btn-circle mr-3" @click="scrollDown"
            ><i class="fas fa-arrow-down px-0"></i
          ></b-button>
        </template>
        <h2 @click="close()" style="cursor: pointer" class="d-inline">
          <b-badge pill variant="primary" class="px-10">Tutup</b-badge>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    close: Function,
    scrollButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollUp() {
      this.$emit("scrollUp");
    },
    scrollDown() {
      this.$emit("scrollDown");
    },
  },
};
</script>

<style>
</style>